
	import { defineComponent } from 'vue';
	import { io, Socket } from 'socket.io-client';
	import Peer from 'peerjs';
	import SentryLogger from '@/utils/SentryLogger';
	import { serverUrl, peerServerUrl, peerServerPort } from '@/utils/envs';

	interface IRoomDom {
		[key: string]: HTMLElement | undefined | null;
	}
	interface IPeers {
		[key: string]: Peer.MediaConnection | undefined;
	}
	interface IContainers {
		[key: string]: HTMLElement | undefined;
	}
	interface IPeerCounter {
		[key: string]: number;
	}
	interface IRoomParameter {
		roomId?: string;
		action?: string;
		videoInputDeviceName?: string;
		streamVideo?: string;
		streamAudio?: string;
	}
	interface IMediaDeviceInfo {
		[key: string]: any[];
	}
	interface IMediaDeviceID {
		videoInputDeviceName: string;
		videoInputDeviceId: string;
	}

	const actions = {
		START: 'start',
		LOBBY: 'welcome',
	};

	const sentryLogger = new SentryLogger();
	const dom: IRoomDom = {
		videoConference: null,
		lobby: null,
		videoGrid: null,
		myVideo: null,
		myVideoInputsSelect: null,
		myAudioInputsSelect: null,
		myHangupButton: null,
	};
	let socket: Socket;
	let myPeer: Peer;
	const peers: IPeers = {};
	const containers: IContainers = {};
	const counters: IPeerCounter = {};

	let roomParameters: IRoomParameter = {
		roomId: '',
		action: actions.LOBBY,
		videoInputDeviceName: 'default',
		streamVideo: 'true',
		streamAudio: 'false',
	};
	const mediaDeviceInfos: IMediaDeviceInfo = {
		videoInputs: [],
		audioInputs: [],
		audioOutputs: [],
	};
	const selectedMediaDeviceIds: IMediaDeviceID = {
		videoInputDeviceName: '',
		videoInputDeviceId: '',
	};

	const streamConfigs = {
		streamVideo: true,
		streamAudio: false,
	};

	async function init() {
		socket = io(serverUrl);
		roomParameters = { ...roomParameters, ...getRoomParameters() };
		initDomVariables();
		initDomEvents();
		await loadMediaDevices();
		setStreamConfigs();

		if (roomParameters.action === actions.START) {
			showVideoConference();
			await initMediaDevice();
		} else if (roomParameters.action === actions.LOBBY) {
			showLobby();
		}
	}

	function getRoomParameters() {
		const pairs = window.location.search.substring(1).split('&');
		const urlParameters: { [key: string]: string; roomId: string } = { roomId: '' };

		pairs.forEach((pair) => {
			if (pair === '') return;
			const query = pair.split('=');
			const key = decodeURIComponent(query[0]) as string;
			urlParameters[key] = decodeURIComponent(query[1]);
		});
		urlParameters.roomId = window.location.pathname.split('/').pop() || '';
		return urlParameters;
	}

	function initDomVariables() {
		dom.videoConference = document.getElementById('video-conference');
		dom.lobby = document.getElementById('lobby');
		dom.startVideoConference = document.getElementById('start-video-conference');
		dom.videoGrid = document.getElementById('video-grid');
		dom.myVideo = document.getElementById('my-video');
		dom.myVideoInputsSelect = document.getElementById('my-media-devices-video-inputs');
		dom.myHangupButton = document.getElementById('my-hangup');
	}

	function initDomEvents() {
		if (dom.myVideo) {
			(dom.myVideo as HTMLVideoElement).muted = true;
		}
		if (dom.startVideoConference) {
			dom.startVideoConference.onclick = async () => {
				showVideoConference();
				await initMediaDevice();
			};
		}
		if (dom.myHangupButton) {
			dom.myHangupButton.onclick = () => hangup();
		}
	}

	async function loadMediaDevices() {
		try {
			const devices = await navigator.mediaDevices.enumerateDevices();
			const isTextSimilar = (text1: string, text2: string) => {
				const text1Lower = text1.toLowerCase();
				const text2Lower = text2.toLowerCase();
				return text1Lower.includes(text2Lower) || text2Lower.includes(text1Lower);
			};
			devices.forEach((device) => {
				console.log(`${device.kind}: ${device.label} id = ${device.deviceId}`);
				if (device.kind === 'videoinput') {
					mediaDeviceInfos.videoInputs.push({
						name: device.label,
						id: device.deviceId,
					});
					if (
						roomParameters.videoInputDeviceName &&
						isTextSimilar(device.label, roomParameters.videoInputDeviceName)
					) {
						selectedMediaDeviceIds.videoInputDeviceId = device.deviceId;
						console.log(
							`* Device Name ${roomParameters.videoInputDeviceName} => ${device.label} : id is ${selectedMediaDeviceIds.videoInputDeviceId}`
						);
					}
				}
				if (device.kind === 'audioinput') {
					mediaDeviceInfos.audioInputs.push({
						name: device.label,
						id: device.deviceId,
					});
				}
				if (device.kind === 'audiooutput') {
					mediaDeviceInfos.audioOutputs.push({
						name: device.label,
						id: device.deviceId,
					});
				}
			});
		} catch (err) {
			console.log(`${(err as Error).name}: ${(err as Error).message}`);
		}
	}

	function setStreamConfigs() {
		streamConfigs.streamVideo = roomParameters.streamVideo === 'true';
		streamConfigs.streamAudio = roomParameters.streamAudio === 'true';
	}

	function showVideoConference() {
		if (dom.videoConference && dom.lobby) {
			dom.videoConference.classList.remove('hidden');
			dom.lobby.classList.add('hidden');
		}
	}

	async function initMediaDevice() {
		sentryLogger.debug('initializing media device');
		const constraints: { audio: any; video: any } = {
			audio: false,
			video: {},
		};
		if (streamConfigs.streamAudio) {
			constraints.audio = {
				echoCancellation: true,
				noiseSuppression: true,
			};
		}
		if (streamConfigs.streamVideo) {
			// FIXME: this configs are specific for Brio Logitech 4k
			constraints.video = {
				width: {
					min: 320,
					ideal: 1920,
					max: 1920,
				},
				height: {
					min: 180,
					ideal: 1080,
					max: 1080,
				},
				frameRate: {
					min: 1,
					ideal: 20,
					max: 30,
				},
				aspectRatio: 16 / 9,
				facingMode: 'environment',
			};
			if (selectedMediaDeviceIds.videoInputDeviceId) {
				constraints.video.deviceId = { exact: selectedMediaDeviceIds.videoInputDeviceId };
				sentryLogger.debug(`* VideoInputDevice constraint => ${JSON.stringify(constraints.video.deviceId)}`);
			} else {
				sentryLogger.debug('NO Specific VideoInputDevice constraint');
			}
		}

		try {
			sentryLogger.debug('media device initialized');
			const myStream = await navigator.mediaDevices.getUserMedia(constraints);
			fillVideoInputsList(dom.myVideoInputsSelect as HTMLSelectElement);
			initPeerAndSocketEvents(myStream);
		} catch (error) {
			sentryLogger.exception(error as Error);
		}
	}

	function fillVideoInputsList(select: HTMLSelectElement | null | undefined) {
		if (!select) {
			return;
		}

		async function listener() {
			sentryLogger.info(`Media Video Input selected: ${select?.value}`);
			selectedMediaDeviceIds.videoInputDeviceId = select?.value || '';
			await initMediaDevice();
		}

		select.removeEventListener('change', listener, false);
		while (select.firstChild) {
			select.removeChild(select.firstChild);
		}
		for (let i = 0; i < mediaDeviceInfos.videoInputs.length; i += 1) {
			const option = document.createElement('option');
			option.text = mediaDeviceInfos.videoInputs[i].name;
			option.value = mediaDeviceInfos.videoInputs[i].id;
			select.append(option);
			if (mediaDeviceInfos.videoInputs[i].id === selectedMediaDeviceIds.videoInputDeviceId) {
				option.selected = true;
			}
		}

		select.addEventListener('change', listener);
	}

	function initPeerAndSocketEvents(myStream: MediaStream) {
		sentryLogger.info('Starting telepresence peer and sockets');
		myPeer = new Peer(undefined, {
			host: peerServerUrl,
			port: peerServerPort,
			path: '/myapp/',
			config: {
				iceServers: [
					{
						urls: ['stun:us-turn8.xirsys.com'],
					},
					{
						username:
							'1gzEdlKRFFrrp4PoehmL-L6-aVc16tX5OLY7Ia3rjPYx_owZK5D1XLeTMRaki9-4AAAAAGGEES53ZWJydGM0cm9iaW9z',
						credential: '6b24cb7a-3d90-11ec-b36b-0242ac140004',
						urls: [
							'turn:us-turn8.xirsys.com:80?transport=udp',
							'turn:us-turn8.xirsys.com:3478?transport=udp',
							'turn:us-turn8.xirsys.com:80?transport=tcp',
							'turn:us-turn8.xirsys.com:3478?transport=tcp',
							'turns:us-turn8.xirsys.com:443?transport=tcp',
							'turns:us-turn8.xirsys.com:5349?transport=tcp',
						],
					},
				],
			},
		});

		socket = io(serverUrl);
		myPeer.on('open', (id) => handlePeerOpen(id, myStream));
		myPeer.on('call', (call) => handlePeerIncomingCall(call, myStream));
		myPeer.on('error', (err) => sentryLogger.error(`this.myPeer error type=${err.type}`));
		socket.on('user-connected', (userId) => callPeer(userId, myStream));
		socket.on('user-disconnected', (userId) => disconnectPeer(userId));
	}

	function handlePeerOpen(id: string, myStream: MediaStream) {
		// when this user is connected to peer server
		socket.emit('join-room', roomParameters.roomId, id);
		sentryLogger.info(`New User for room ${roomParameters.roomId}, platform=${window.navigator.userAgent}`);
		sentryLogger.info(`current user id is ${myPeer.id}`);

		if (!dom.myVideo) {
			return;
		}
		dom.myVideo.setAttribute('id', `video-${myPeer.id}`);
		addVideoStream(myPeer.id, dom.myVideo as HTMLVideoElement, myStream, false, 'initMediaDevice');
	}

	function handlePeerIncomingCall(call: Peer.MediaConnection, myStream: MediaStream) {
		sentryLogger.info(`A remote user with id ${call.peer} is calling`);
		call.answer(myStream);

		if (call.metadata === 'user-no-stream') {
			return;
		}
		const video = createElement('video', `video-${call.peer}`);

		// As we open an audio and video stream, we will receive twice the call.on(stream), one for video and one for audio
		// So we ignor the second one
		let hasAlreadyBeenCalled = false;
		let n = 0;

		call.on('stream', (userVideoStream: MediaStream) => {
			if (hasAlreadyBeenCalled === false) {
				hasAlreadyBeenCalled = true;
				addVideoStream(
					call.peer,
					video as HTMLVideoElement,
					userVideoStream,
					'myPeer.on.call.on(stream)',
					undefined
				);
			} else {
				sentryLogger.info(
					`User id ${
						call.peer
					} : ignore duplicated call of on(stream), because of audio and video stream _ n=${(n += 1)}`
				);
			}
		});
	}

	function callPeer(userId: string, myStream: MediaStream) {
		sentryLogger.info(`Calling peer ${userId}`);
		const call = myPeer.call(userId, myStream);
		const video = createElement('video', `video-${userId}`);
		// As we open an audio and video stream, we will receive twice the call.on(stream), one for video and one for audio
		// So we ignor the second one
		let hasAlreadyBeenCalled = false;

		call.on('stream', (userVideoStream) => {
			if (hasAlreadyBeenCalled === false) {
				hasAlreadyBeenCalled = true;
				addVideoStream(
					userId,
					video as HTMLVideoElement,
					userVideoStream,
					true,
					'connectToNewUser.call.on(stream)'
				);
			} else {
				sentryLogger.debug('ignore duplicated call of on(stream), because of audio and video stream');
			}
		});

		call.on('close', () => {
			sentryLogger.debug(`Closing user ${userId} connection`);
		});
		peers[userId] = call;
	}

	function disconnectPeer(userId: string) {
		sentryLogger.info(`User disconnected : ${userId}`);
		if (userId && peers[userId]) {
			peers[userId]?.close();
			delete peers[userId];
		}
		if (userId && containers[userId]) {
			containers[userId]?.remove();
			delete containers[userId];
		}
		delete counters[userId];
	}

	function addVideoStream(
		userId: string,
		video: HTMLVideoElement | null | undefined,
		stream: MediaStream,
		thisIsAnExternalStream: string | boolean,
		callingSource: string | undefined
	) {
		if (!video) {
			return;
		}
		sentryLogger.info(`Adding Video Stream from ${callingSource} user id=${userId}`);
		video.srcObject = stream;
		initZoomCallbacks(video as HTMLVideoElement);
		const div = createElement('div', `div-${userId}`);
		const label = createElement('span', `span-${userId}`);
		div.classList.toggle('video-container');

		video.addEventListener('loadedmetadata', () => {
			video.play();
			label.className = 'video-text';
			label.append(`${userId.slice(-4)} : ${video.videoWidth}x${video.videoHeight}`);
		});

		video.addEventListener('timeupdate', () => {
			let counter = counters[userId];

			if (counter === undefined) {
				counter = 0;
			}
			const text = `${userId.slice(-4)} : ${video.videoWidth}x${video.videoHeight}${
				counter % 2 === 0 ? '.' : ' '
			}`;
			label.textContent = text;
			counters[userId] = counter + 1;
		});

		// Only append to new div when this IS NOT the current user
		if (thisIsAnExternalStream) {
			div.append(label);
			div.append(video);
			if (dom.videoGrid) {
				dom.videoGrid.append(div);
			}
			containers[userId] = div;
		}
	}

	function createElement(type: string, id: string) {
		const element = document.createElement(type);
		element.setAttribute('id', id);
		return element;
	}

	function showLobby() {
		if (dom.videoConference && dom.lobby) {
			dom.videoConference.classList.add('hidden');
			dom.lobby.classList.remove('hidden');
		}
	}

	function initZoomCallbacks(videoElement: HTMLElement) {
		videoElement.addEventListener('click', () => {
			if (videoElement.classList.contains('video-scaledx2')) {
				videoElement.classList.toggle('video-scaledx2');
				videoElement.classList.toggle('video-scaledx3');
				return;
			}
			if (videoElement.classList.contains('video-scaledx3')) {
				videoElement.classList.toggle('video-scaledx3');
				videoElement.classList.toggle('video-scaledx4');
				return;
			}
			if (videoElement.classList.contains('video-scaledx4')) {
				videoElement.classList.toggle('video-scaledx4');
				videoElement.classList.toggle('video-scaledx8');
				return;
			}
			if (videoElement.classList.contains('video-scaledx8')) {
				videoElement.classList.toggle('video-scaledx8');
				videoElement.classList.toggle('video-scaledx12');
				return;
			}
			if (videoElement.classList.contains('video-scaledx12')) {
				videoElement.classList.toggle('video-scaledx12');
				return;
			}
			videoElement.classList.toggle('video-scaledx2');
		});
	}

	function hangup() {
		sentryLogger.debug('Hangup event called, stopping meeting.');
		Object.entries(containers).forEach(([id, video]) => {
			video?.remove();
			delete containers[id];
		});
		Object.keys(counters).forEach((id) => delete counters[id]);

		disconnectPeers();
		myPeer?.disconnect();
		socket?.disconnect();
	}

	function disconnectPeers() {
		if (myPeer && myPeer.connections) {
			Object.values(myPeer.connections).forEach((connections: any) => {
				connections?.forEach(
					(
						conn: { connectionId: any; peerConnection: { close: () => void }; close: () => void },
						index: number,
						array: string | any[]
					) => {
						console.log(
							`closing ${conn.connectionId} peerConnection (${index + 1}/${array.length})`,
							conn.peerConnection
						);
						conn.peerConnection?.close();
						conn?.close();
					}
				);
			});
		}
	}

	export default defineComponent({
		name: 'Room',
		mounted() {
			init();
		},
		beforeRouteLeave(to, from, next) {
			hangup();
			next();
		},
	});
